import { all, takeEvery, put } from 'redux-saga/effects'
// import axios from "axios"
import actions from './actions'
import { loginRequestAPI } from '../../apis/auth'
import { toast } from 'react-toastify'


export function* WATCH_SIGN_IN(action) {
    const resp = yield loginRequestAPI(action.payload)

    if (resp?.data?.token) {

        const token = resp?.data?.token
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(resp?.data?.user))

        yield put({
            type: actions.SET_STATE,
            payload: {
                user: resp.data.user,
                token: resp.data.token
            }
        })

    } else {
        toast.error("User Exist")
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.SIGN_IN, WATCH_SIGN_IN),

    ])
}