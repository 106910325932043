const actions = {
  SIGN_IN: "SIGN_IN",
  CHECK_AUTH_TOKEN_TIME: "CHECK_AUTH_TOKEN_TIME",
  SIGN_OUT: "SIGN_OUT",
  SET_STATE: "SET_STATE",
  UPDATE_PROFILE: "UPDAT_PROFILE",
  FORGOT_PASSWORD_LINK: "FORGOT_PASSWORD_LINK",
  RESET_PASSWORD: "RESET_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  LOADING: "LOADING",
  ERROR: "ERROR"
}

export default actions