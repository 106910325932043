import actions from "./actions";

const initialState = {
    loading: false,
    workloads: null,
    error: null,
    isSuccess: false,
    withTrackerPercentage: 0,
    withoutTrackerPercentage: 0,
    freeHoursPercentage: 0
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actions.WORKLOAD_LOADING:
            return { ...state, loading: true, isSuccess: false, error: null };

        case actions.SET_WORKLOAD:
            return { ...state, ...payload, loading: false, isSuccess: false };

        case actions.WORKLOAD_ERROR:
            return { ...state, ...payload, loading: false, isSuccess: false };

        case actions.WORKLOAD_ISSUCCESS:
            return { ...state, ...payload, isSuccess: true, loading: false, error: null };
        default:
            return state;
    }
};
