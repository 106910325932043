import { all, takeEvery, put } from 'redux-saga/effects'
// import axios from "axios"
import { createAndUpdateProjects, deleteProject, getProjectByuserID, getProjects } from '../../apis/project'
import actions from "./actions"

function* WATCH_CREATE_UPDATE_PPROJECTS(action) {
    yield put({
        type: actions.PROJECT_LOADING
    })
    try {
        const resp = yield createAndUpdateProjects(action.payload)
        if (resp?.data?.id || resp?.data?.msg === "Record Updated") {
            yield put({
                type: actions.PROJECT_ISSUCCESS
            })
        }
    } catch (err) {
        yield put({
            type: actions.PROJECT_ERROR,
            payload: { error: err?.response?.data?.msg }
        })
    }
}

function* WATCH_GET_PROJECTS(action) {
    yield put({
        type: actions.PROJECT_LOADING
    })
    const resp = yield getProjects(action.payload)
    if (resp.data) {
        yield put({
            type: actions.SET_PROJECTS,
            payload: { projects: resp.data }
        })
    }
}
function* WATCH_DELETE_PROJECT(action) {
    yield put({
        type: actions.PROJECT_LOADING
    })
    const resp = yield deleteProject(action.payload)
    if (resp?.data?.msg === "Record Deleted") {
        yield put({
            type: actions.PROJECT_ISSUCCESS
        })
    }
}
function* WATCH_GET_PROJECT_BY_USER(action) {
    yield put({
        type: actions.PROJECT_LOADING
    })
    const resp = yield getProjectByuserID(action.payload)
    if (resp.data) {
        yield put({
            type: actions.SET_PROJECTS,
            payload: { projects: resp.data }
        })
    }
}
export default function* rootSaga() {
    yield all([
        takeEvery(actions.CREATE_AND_UPDATE_PROJECTS, WATCH_CREATE_UPDATE_PPROJECTS),
        takeEvery(actions.GET_PROJECTS, WATCH_GET_PROJECTS),
        takeEvery(actions.DELETE_PROJECTS, WATCH_DELETE_PROJECT),
        takeEvery(actions.GET_PROJECTS_BY_USER, WATCH_GET_PROJECT_BY_USER)
    ])
}