import { all } from "redux-saga/effects"
import auth from './auth/saga'
import user from './user/saga'
import role from './roles/saga'
import designation from './designation/saga'
import project from './project/saga'
import workload from './workload/saga'

export default function* rootSaga() {
    yield all([auth(), designation(), user(), role(), project(), workload()])
}