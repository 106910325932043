import apiCall from '../../utility/apiCall'

export const createAndUpdateProjects = async (payload) => {
  return await apiCall.post('/projects/create&update', payload)
}

export const getProjects = async (payload) => {
  return await apiCall.post(
    `/projects?page=${payload.page}&perPage=${payload?.perPage}`,
  )
}

export const deleteProject = async (id) => {
  return await apiCall.delete(`/projects/delete?id=${id}`);
};

export const getProjectByuserID = async (id) => {
  return await apiCall.get(`/projects/user?id=${id}`);
};
