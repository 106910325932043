import { all, takeEvery, put } from 'redux-saga/effects'
// import axios from "axios"
import actions from './actions'
import { getRoles } from '../../apis/roles'

function* WATCH_GET_ROLES() {
    yield put({
        type: actions.LOADING
    })
    const resp = yield getRoles()
    if (resp.data) {
        yield put({
            type: actions.SET_ROLES,
            payload: { roles: resp.data }
        })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_ROLES, WATCH_GET_ROLES)
    ])
}