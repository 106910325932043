import actions from "./actions";

const initialState = {
    loading: false,
    designations: null,
    error: null,
    isSuccess: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case actions.DESIGNATION_LOADING:
            return { ...state, loading: true, isSuccess: false, error: null };
        case actions.SET_DESIGNATION:
            return { ...state, error: null, ...payload, loading: false, isSuccess: false };
        case actions.DESIGNATION_ERROR:
            return { ...state, ...payload, loading: false, isSuccess: false };
        case actions.DESIGNATION_ISSUCCESS:
            return { ...state, ...payload, isSuccess: true, loading: false, error: null };
        default:
            return state;
    }
};
