import apiCall from "../../utility/apiCall";

export const authApiRoutes = {
  LOGIN: "/login",
  UPDATEPASSWORD: "/updatepassword"
};

export const loginRequestAPI = async (payload) => {
  return await apiCall.post(authApiRoutes.LOGIN, payload);
};

export const updatePassword = async (id, currentPassword, newPassword) => {
  return await apiCall.put(`${authApiRoutes.UPDATEPASSWORD}?id=${id}&currentpassword=${currentPassword}`, { newPassword });
};
export const check_token = async (token) => {
  return await apiCall.post(`/check-token?token=${token}`)
}