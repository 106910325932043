import apiCall from "../../utility/apiCall";

export const createAndUpdateWorkLoad = async (payload) => {
    return await apiCall.post(`/workload/create&update`, payload);
};

export const getWorkloads = async (payload) => {
    return await apiCall.post(
        `/workloads?page=${payload?.page}&perPage=${payload?.perPage}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`,
    )
}