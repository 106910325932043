import { all, takeEvery, put } from 'redux-saga/effects'
// import axios from "axios"
import actions from './actions'
import { createAndUpdateUser, deleteUser, getAllEmployees, getTeamLeadersAndProjectManager, getUsers } from './../../apis/user/index';

function* WATCH_CREATE_UPDATE_USER(action) {
    yield put({
        type: actions.USER_LOADING
    })
    try {
        const resp = yield createAndUpdateUser(action.payload)
        if (resp?.data?.id || resp?.data?.msg === "Record Updated") {
            yield put({
                type: actions.USER_ISSUCCESS
            })
        }
    } catch (err) {
        yield put({
            type: actions.USER_ERROR,
            payload: { error: err?.response?.data?.msg }
        })
    }
}

function* WATCH_GET_USERS(action) {
    yield put({
        type: actions.USER_LOADING
    })
    const resp = yield getUsers(action.payload)
    if (resp.data) {
        yield put({
            type: actions.SET_USER,
            payload: { users: resp.data }
        })
    }
}
function* WATCH_DELETE_USERS(action) {
    yield put({
        type: actions.USER_LOADING
    })
    const resp = yield deleteUser(action.payload)
    if (resp?.data?.msg === "Record Deleted") {
        yield put({
            type: actions.USER_ISSUCCESS
        })
    }
}
function* WATCH_GET_ALL_EMPLOYEES() {
    yield put({
        type: actions.USER_LOADING
    })
    const resp = yield getAllEmployees()
    if (resp.data) {
        yield put({
            type: actions.SET_USER,
            payload: { users: resp.data }
        })
    }
}

function* WATCH_GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS() {
    yield put({
        type: actions.USER_LOADING
    })
    const resp = yield getTeamLeadersAndProjectManager()
    if (resp.data) {
        yield put({
            type: actions.SET_USER,
            payload: { users: resp.data }
        })
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.CREATE_AND_UPDATE_USER, WATCH_CREATE_UPDATE_USER),
        takeEvery(actions.GET_USER, WATCH_GET_USERS),
        takeEvery(actions.DELETE_USER, WATCH_DELETE_USERS),
        takeEvery(actions.GET_ALL_EMPLOYEES, WATCH_GET_ALL_EMPLOYEES),
        takeEvery(actions.GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS, WATCH_GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS)
    ])
}