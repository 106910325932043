// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./auth/authReducer";
import users from "./user/userReducer";
import roles from "./roles/reducer";
import ProjectReducer from "./project/projectReducer"
import { combineReducers } from "redux";
import designation from "./designation/reducer"
import workload from "./workload/reducer"

const rootReducer = combineReducers({
  navbar,
  layout,
  auth,
  designation,
  users,
  roles,
  project: ProjectReducer,
  workload
})

export default rootReducer;
