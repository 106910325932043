import { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isJsonString } from "../helper";

export const AppContext = createContext();

const getRoleId = () => {
  return isJsonString(localStorage.getItem("currentRole"))
    ? JSON.parse(localStorage.getItem("currentRole"))
    : localStorage.getItem("currentRole");
};

export const AppProvider = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const [loginUserData, setLoginUserData] = useState(null);
  const [userRoles, setUserRoles] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState(getRoleId());

  useEffect(() => {
    setLoginUserData(user);
  }, [user]);

  useEffect(() => {
    // if (loginUserData) {
    //   const roles = loginUserData?.userRoles.map((item) => item?.role);
    //   setUserRoles(roles);
    //   if (
    //     currentUserRole === null ||
    //     currentUserRole === undefined ||
    //     currentUserRole === "null" ||
    //     currentUserRole === "undefined" ||
    //     currentUserRole === "" ||
    //     currentUserRole === {}
    //   ) {
    //     setCurrentUserRole(roles.length ? roles[0] : null);
    //     localStorage.setItem(
    //       "currentRole",
    //       JSON.stringify(roles.length ? roles[0] : null)
    //     );
    //   }
    // } else {
    //   setUserRoles([]);
    // }
  }, [loginUserData]);

  const context = {
    currentUserRole,
    setCurrentUserRole,
    loginUserData,
    userRoles,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
