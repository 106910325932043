import actions from "./actions";

const initialState = {
  loading: false,
  users: null,
  error: null,
  isSuccess: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.USER_LOADING:
      return { ...state, loading: true, isSuccess: false, error: null };

    case actions.SET_USER:
      return { ...state, ...payload, loading: false, isSuccess: false };

    case actions.USER_ERROR:
      return { ...state, ...payload, loading: false, isSuccess: false };

    case actions.USER_ISSUCCESS:
      return { ...state, ...payload, isSuccess: true, loading: false, error: null };
    default:
      return state;
  }
};
