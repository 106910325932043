const actions = {
    GET_DESIGNATION: "GET_DESIGNATION",
    GET_ALL_DESIGNATION: "GET_ALL_DESIGNATION",
    SET_DESIGNATION: "SET_DESIGNATION",
    CREATE_AND_UPDATE_DESIGNATION: "CREATE_AND_UPDATE_DESIGNATION",
    DESIGNATION_LOADING: "DESIGNATION_LOADING",
    DELETE_DESIGNATION: "DELETE_DESIGNATION",
    UPDATE_DESIGNATION: "UPDATE_DESIGNATION",
    FILTER_DESIGNATION: "FILTER_DESIGNATION",
    DESIGNATION_ERROR: "DESIGNATION_ERROR",
    DESIGNATION_ISSUCCESS: "DESIGNATION_ISSUCCESS"
}

export default actions