import apiCall from "../../utility/apiCall";

export const createAndUpdateUser = async (payload) => {
  return await apiCall.post("/user/create&update", payload);
};

export const getUsers = async (payload) => {
  return await apiCall.post(`/users?page=${payload.page}&perPage=${payload?.perPage}`);
};

export const deleteUser = async (id) => {
  return await apiCall.delete(`/users/delete?id=${id}`);
};

export const getAllEmployees = async () => {
  return await apiCall.get(`/users/employees/all`);
};

export const getTeamLeadersAndProjectManager = async () => {
  return await apiCall.get(`/users/employees/teamleaders&projectmanagers`);
};
