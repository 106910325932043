const actions = {
  GET_USER: "GET_USER",
  SET_USER: "SET_USER",

  CREATE_AND_UPDATE_USER: "CREATE_AND_UPDATE_USER",
  USER_LOADING: "USER_LOADING",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER: "UPDATE_USER",
  FILTER_USER: "FILTER_USER",
  USER_ERROR: "USER_ERROR",
  USER_ISSUCCESS: "USER_ISSUCCESS",
  GET_ALL_EMPLOYEES: "GET_ALL_EMPLOYEES",
  GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS: "GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS"
}
export default actions