import actions from './actions'

const initialState = {
  authLoading: false,
  token: null,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.LOADING:
      return { ...state, authLoading: true };

    case actions.SET_STATE:
      return { ...state, ...payload, authLoading: false };

    case actions.ERROR:
      return { ...state, ...payload, authLoading: false };

    default:
      return state;
  }
};