import axios from "axios";
import { SERVER_URL } from "../configs/config";

const apiCall = axios.create({
  baseURL: SERVER_URL,
});

const getToken = () => {
  return localStorage.getItem("token");
};

// For GET requests
apiCall.interceptors.request.use(
  (req) => {
    // Add configurations here

    const accessToken = getToken();
    if (accessToken) {
      // ** eslint-disable-next-line no-param-reassign
      req.headers.authorization = `Bearer ${accessToken}`;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
apiCall.interceptors.response.use(
  (res) => {

    // Add configurations here
    if (res.status === 200) {
      // localStorage.setItem("token", res.data.token)
    }
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default apiCall;
