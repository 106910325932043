import actions from "./actions";

const initialState = {
  loading: false,
  roles: null,
  error: null,
  isSuccess: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.LOADING:
      return { ...state, loading: true, isSuccess: false, error: null };

    case actions.SET_ROLES:
      return { ...state, ...payload, loading: false, isSuccess: false };

    case actions.ROLES_ERROR:
      return { ...state, ...payload, loading: false, isSuccess: false };

    case actions.ISSUCCESS:
      return { ...state, ...payload, isSuccess: true, loading: false, error: null };
    default:
      return state;
  }
};
