import apiCall from "../../utility/apiCall";


export const createAndUpdateDesignation = async (data) => {
    if (data?.designation?.id) data = data.designation
    return await apiCall.post('/designation/create&update', data);
};

export const getDesignation = async (payload) => {
    return await apiCall.post(`/designations?page=${payload.page}&perPage=${payload?.perPage}`, payload?.filter);
};

export const deleteDesignation = async (id) => {
    return await apiCall.delete(`/designation/delete?id=${id}`);
};

export const getAllDesignations = async () => {
    return await apiCall.get(`/designations/all`);
};