const actions = {
    GET_PROJECTS: "GET_PROJECTS",
    SET_PROJECTS: "SET_PROJECTS",

    CREATE_AND_UPDATE_PROJECTS: "CREATE_AND_UPDATE_PROJECTS",
    PROJECT_LOADING: "PROJECT_LOADING",
    DELETE_PROJECTS: "DELETE_PROJECTS",
    UPDATE_PROJECTS: "UPDATE_PROJECTS",
    FILTER_PROJECTS: "FILTER_PROJECTS",
    PROJECT_ERROR: "PROJECT_ERROR",
    PROJECT_ISSUCCESS: "PROJECT_ISSUCCESS",
    GET_ALL_EMPLOYEES: "GET_ALL_EMPLOYEES",
    GET_PROJECTS_BY_USER: "GET_PROJECTS_BY_USER",
}
export default actions