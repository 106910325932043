const actions = {
    GET_WORKLOAD: "GET_WORKLOAD",
    SET_WORKLOAD: "SET_WORKLOAD",
    CREATE_AND_UPDATE_WORKLOAD: "CREATE_AND_UPDATE_WORKLOAD",
    WORKLOAD_LOADING: "WORKLOAD_LOADING",
    DELETE_WORKLOAD: "DELETE_WORKLOAD",
    UPDATE_WORKLOAD: "UPDATE_WORKLOAD",
    FILTER_WORKLOAD: "FILTER_WORKLOAD",
    WORKLOAD_ERROR: "WORKLOAD_ERROR",
    WORKLOAD_ISSUCCESS: "WORKLOAD_ISSUCCESS",
    GET_ALL_EMPLOYEES: "GET_ALL_EMPLOYEES",
    GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS: "GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS"
}
export default actions