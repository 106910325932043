import { all, takeEvery, put } from 'redux-saga/effects'
// import axios from "axios"
import actions from './actions'
import { createAndUpdateDesignation, deleteDesignation, getAllDesignations, getDesignation } from './../../apis/designation/index';

function* WATCH_CREATE_DESIGNATION(action) {
    yield put({
        type: actions.DESIGNATION_LOADING
    })
    try {
        const resp = yield createAndUpdateDesignation(action.payload)
        if (resp?.data?.id || resp?.data?.msg === "Record Updated") {
            yield put({
                type: actions.DESIGNATION_ISSUCCESS
            })
        }
    } catch (err) {
        if (err?.response?.data?.msg === "Designation already exist") {
            yield put({
                type: actions.DESIGNATION_ERROR,
                payload: { error: err?.response?.data?.msg }
            })
        }
    }
}

function* WATCH_GET_DESIGNATION(action) {
    yield put({
        type: actions.DESIGNATION_LOADING
    })
    const resp = yield getDesignation(action.payload)
    if (resp.data) {
        yield put({
            type: actions.SET_DESIGNATION,
            payload: { designations: resp.data }
        })
    }
}

function* WATCH_DELETE_DESIGNATION(action) {
    yield put({
        type: actions.DESIGNATION_LOADING
    })
    const resp = yield deleteDesignation(action.payload)
    if (resp?.data?.msg === "Record Deleted") {
        yield put({
            type: actions.DESIGNATION_ISSUCCESS
        })
    }
}

function* WATCH_GET_ALL_DESIGNATION() {
    yield put({
        type: actions.DESIGNATION_LOADING
    })
    const resp = yield getAllDesignations()
    if (resp.data) {
        yield put({
            type: actions.SET_DESIGNATION,
            payload: { designations: resp.data }
        })
    }
}
export default function* rootSaga() {
    yield all([
        takeEvery(actions.CREATE_AND_UPDATE_DESIGNATION, WATCH_CREATE_DESIGNATION),
        takeEvery(actions.GET_DESIGNATION, WATCH_GET_DESIGNATION),
        takeEvery(actions.DELETE_DESIGNATION, WATCH_DELETE_DESIGNATION),
        takeEvery(actions.GET_ALL_DESIGNATION, WATCH_GET_ALL_DESIGNATION),
    ])
}