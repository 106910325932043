import { all, takeEvery, put } from 'redux-saga/effects'
// import axios from "axios"
import actions from './actions'
import { createAndUpdateWorkLoad, getWorkloads } from './../../apis/workloadDetails/index';

function* WATCH_CREATE_UPDATE_WORKLOAD(action) {
    yield put({
        type: actions.WORKLOAD_LOADING
    })
    try {
        const resp = yield createAndUpdateWorkLoad(action.payload)
        if (resp?.data?.id || resp?.data?.msg === "success") {
            yield put({
                type: actions.WORKLOAD_ISSUCCESS
            })
        }
    } catch (err) {
        yield put({
            type: actions.WORKLOAD_ERROR,
            payload: { error: err?.response?.data?.msg }
        })
    }
}

function* WATCH_GET_WORKLOADS(action) {
    yield put({
        type: actions.WORKLOAD_LOADING
    })
    const resp = yield getWorkloads(action.payload)
    if (resp.data) {
        yield put({
            type: actions.SET_WORKLOAD,
            payload: { workloads: resp.data.workloads, withTrackerPercentage: resp.data.withTrackerPercentage, freeHoursPercentage: resp.data.freeHoursPercentage, withoutTrackerPercentage: resp.data.withoutTrackerPercentage }
        })
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.CREATE_AND_UPDATE_WORKLOAD, WATCH_CREATE_UPDATE_WORKLOAD),
        takeEvery(actions.GET_WORKLOAD, WATCH_GET_WORKLOADS),
        // takeEvery(actions.DELETE_USER, WATCH_DELETE_USERS),
        // takeEvery(actions.GET_ALL_EMPLOYEES, WATCH_GET_ALL_EMPLOYEES),
        // takeEvery(actions.GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS, WATCH_GET_ALL_PROJECTMANAGER_AND_TEAMLEADERS)
    ])
}