import actions from "./actions";

const initialState = {
    loading: false,
    projects: [],
    error: null,
    isSuccess: false,
};
function ProjectReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actions.PROJECT_LOADING:
            return { ...state, loading: true, isSuccess: false, error: null };

        case actions.SET_PROJECTS:
            return { ...state, ...payload, loading: false, isSuccess: false };

        case actions.PROJECT_ERROR:
            return { ...state, ...payload, loading: false, isSuccess: false };

        case actions.PROJECT_ISSUCCESS:
            return { ...state, ...payload, isSuccess: true, loading: false, error: null };
        default:
            return state;
    }
}


export default ProjectReducer
