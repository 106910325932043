const actions = {
  GET_ROLES: "GET_ROLES",
  SET_ROLES: "SET_ROLES",

  CREATE_ROLES: "CREATE_ROLES",
  LOADING: "LOADING",
  DELETE_ROLES: "DELETE_ROLES",
  UPDATE_ROLES: "UPDATE_ROLES",
  FILTER_ROLES: "FILTER_ROLES",
  ROLES_ERROR: "ROLES_ERROR",
  ISSUCCESS: "ISSUCCESS",
}
export default actions